<template>
  <v-menu 
    offset-y
    open-on-hover
    style="background-color: #FFF;"
    content-class="menu-list"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="selected_types_lancamentos"
      >{{ selectedCostCenter.length === 1 ? selectedCostCenter[0].name : `${selectedCostCenter.length} selecionados` }}</span>
    </template>
    <v-list
      flat
      dense
      height="300"
    >
      <v-list-item>
        <v-list-item-title class="title-list" @click="setCostCenter({ name: 'todos os centros de custos', id: 0 })">
          Todos
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="(costCenter, i) in costCenters"
        :key="i"
      >
      
        <v-list-item-content>
          <v-list-item-title class="title-list" @click="setCostCenter(costCenter)">
            {{ costCenter.name }}
            <v-icon :size="20" v-if="checked(costCenter)" color="green">mdi-check-circle</v-icon>
          </v-list-item-title>
          <template v-if="costCenter.subCostCenters.length">
            <template  v-for="child in costCenter.subCostCenters">
              <v-list-item-content class="pl-4" :key="child.id">
                <v-list-item-action>
                  
                </v-list-item-action>
                <v-list-item-title class="title-list" @click="setCostCenter(child)">
                  {{ child.name }}
                  <v-icon :size="20" v-if="checked(child)" color="green">mdi-check-circle</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "OrganizzeMenuCategoriasCentroCusto",
  props: {
    costCenters: {
      type: Array,
      required: true
    },

    selectedCostCenter: {
      type: Array,
      required: true
    }
  },

  methods: {
    setCostCenter(costCenter) {
      this.$emit('setCostCenter', costCenter)
    },

    checked (costCenter) {
      return !!this.selectedCostCenter.find(item => item.id === costCenter.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/helpers/_variables.scss';
.menu-list {
  background-color: #FFF;

  .title-list {
    cursor: pointer;
  }
}

.selected_types_lancamentos {
  color: $colorPrimaryDarken;
}
</style>